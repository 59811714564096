var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    {
      style: { "min-height": _vm.appBarMinHeight + "px" },
      attrs: {
        app: "",
        extended: _vm.isExtended,
        prominent: "",
        "clipped-left": "",
        color: "header"
      }
    },
    [
      _c(
        "v-container",
        { attrs: { fluid: "", "pt-0": "" } },
        [
          _c(
            "v-row",
            [
              _c("v-img", {
                staticStyle: { height: "20px" },
                attrs: { src: require("@/assets/img/header-top-line.png") }
              }),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    display: "flex",
                    "justify-content": "space-between",
                    margin: "10px"
                  }
                },
                [
                  _c("v-img", {
                    attrs: {
                      src: require("@/assets/img/logo.gif"),
                      "max-width": "170px",
                      "min-width": "170px"
                    }
                  }),
                  _c(
                    "div",
                    { staticStyle: { display: "grid", margin: "3px" } },
                    [
                      _c(
                        "div",
                        [
                          _vm.agencyTel && !_vm.telIconHidden
                            ? _c("TelButton", {
                                attrs: {
                                  agencyTel: _vm.agencyTel,
                                  buttonClass: "float-right"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "mt-1",
                          staticStyle: {
                            "font-size": "10px",
                            "line-height": "100%",
                            "text-align": "center"
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("label.agencyName")) +
                              " : " +
                              _vm._s(_vm.agencyName) +
                              "\n          "
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              ),
              !_vm.headerTitleHidden
                ? _c(
                    "div",
                    { staticClass: "stepper-title" },
                    [
                      _c("v-img", {
                        staticStyle: { height: "35px" },
                        attrs: { src: require("@/assets/img/header-title.png") }
                      }),
                      _c(
                        "div",
                        { staticClass: "stepper-title-text font-weight-bold" },
                        [_vm._v(_vm._s(_vm.stepperTitle()))]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.stepperHidden
                ? _c(
                    "div",
                    { staticClass: "stepper" },
                    [
                      _vm._l(_vm.stepper, function(item) {
                        return [
                          item.step === _vm.stepPoint
                            ? [
                                _c(
                                  "div",
                                  {
                                    key: item.step,
                                    staticClass: "step active",
                                    style: _vm.widthCalculation(item.step)
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "clickable" },
                                      [
                                        _c(
                                          "v-container",
                                          {
                                            staticClass: "ma-auto pa-0",
                                            staticStyle: { height: "50px" },
                                            attrs: { "fill-height": "" }
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              { staticClass: "ma-0 pa-0" },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "text-center ma-auto hearder-font-size white--text font-weight-bold"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(item.text) +
                                                        "\n                    "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm.isReviewContract
                                              ? [
                                                  _c(
                                                    "v-row",
                                                    { staticClass: "ma-0" },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "text-center ma-auto hearder-font-size white--text"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.reviewContractPageRange
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              : _vm._e()
                                          ],
                                          2
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            : item.step < _vm.stepPoint
                            ? [
                                _c(
                                  "div",
                                  {
                                    key: item.step,
                                    staticClass: "step done",
                                    style: _vm.widthCalculation(item.step)
                                  },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticStyle: {
                                          "text-decoration": "none"
                                        },
                                        attrs: {
                                          to: {
                                            name: item.stepName,
                                            hash: item.hash,
                                            params: item.params
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "clickable" },
                                          [
                                            _c(
                                              "v-container",
                                              {
                                                staticClass: "ma-auto px-0",
                                                staticStyle: { height: "50px" },
                                                attrs: { "fill-height": "" }
                                              },
                                              [
                                                _vm.checkPc
                                                  ? [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "text-center ma-auto"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.text)
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  : [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "text-center ma-auto icon"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.step)
                                                          )
                                                        ]
                                                      )
                                                    ]
                                              ],
                                              2
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            : [
                                _c(
                                  "div",
                                  {
                                    key: item.step,
                                    staticClass: "step",
                                    style: _vm.widthCalculation(item.step)
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "clickable" },
                                      [
                                        _c(
                                          "v-container",
                                          {
                                            staticClass: "ma-auto px-0",
                                            staticStyle: { height: "50px" },
                                            attrs: { "fill-height": "" }
                                          },
                                          [
                                            _vm.checkPc
                                              ? [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-center ma-auto"
                                                    },
                                                    [_vm._v(_vm._s(item.text))]
                                                  )
                                                ]
                                              : [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-center ma-auto icon"
                                                    },
                                                    [_vm._v(_vm._s(item.step))]
                                                  )
                                                ]
                                          ],
                                          2
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                        ]
                      })
                    ],
                    2
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }